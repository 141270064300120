<template>
  <b-card
    no-body
  > <b-card-header>

      <b-card-title>
        <div class="d-flex">
          <feather-icon
            icon="ClockIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Time Gaps
          </h4>
        </div>
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Recommended time of stay"
            label-for="estimatedTimeOfStay"
            class="mb-2"
          >

            <vue-slider
              id="estimatedTimeOfStay"
              v-model="data.estimated_time_of_stay"
              :direction="direction"
              :min="5"
              :max="460"
              :interval="5"
              :tooltip="'none'"
            />
            {{ timeOfStay }}
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BCardBody, BCardHeader, BCardTitle, BTooltip, BFormTextarea, BCardText,
} from 'bootstrap-vue'
import {
  required, confirmed,
} from '@validations'

import store from '@/store'
import VueSlider from 'vue-slider-component'

export default {
  components: {

    BFormGroup,
    BForm,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    VueSlider,

  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      point: this.data,
      dir: 'ltr',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
    timeOfStay() {
      const num = this.data.estimated_time_of_stay
      const hours = Math.floor(num / 60)
      const minutes = num % 60
      return `${hours} hrs : ${minutes} min`
    },
  },
  setup() {
  },

}
</script>
<style lang="scss" >
    @import '@core/scss/vue/libs/vue-slider.scss';
</style>
