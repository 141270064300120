import { AbilityBuilder, Ability } from '@casl/ability'
import store from '@/store'

export default function defineAbilityFor(user) {
  const { can, rules } = new AbilityBuilder()
  can('update', 'Point', { user_id: user.id })
  return new Ability(rules)
}

export function defineAbilityForCurrentUser() {
  const user = store.getters['account/user']
  const { can, rules } = new AbilityBuilder()
  can('update', 'Point', { user_id: user.id })
  return new Ability(rules)
}
