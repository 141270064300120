<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>
        <div class="d-flex">
          <feather-icon
            icon="CompassIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Coordinates
          </h4>
        </div>
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <div id="PointGoogleMap">
            <div>

              <b-input-group>
                <b-input-group-prepend>
                  <b-button
                    variant="outline-primary"
                    @click.prevent="reset"
                  >
                    <feather-icon icon="XSquareIcon" />
                  </b-button>
                </b-input-group-prepend>
                <GmapAutocomplete
                  ref="autocomplete"
                  class="form-control"
                  :options="{fields: ['geometry', 'formatted_address', 'address_components']}"
                  @place_changed="setPlace"
                />

                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click.prevent="addMarker"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <br>
            <GmapMap
              :center="center"
              :zoom="12"
              style="width:100%;  height: 240px;"
            >
              <GmapMarker
                v-for="(m, index) in markers"
                :key="index"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="center=m.position"
                @dragend="updatePosition($event.latLng)"
              />
            </GmapMap>
          </div>
          <b-form-group
            label="Latitude"
            label-for="lat"
          >
            <b-form-input
              id="lat"
              v-model="point.lat"
            />
          </b-form-group>
          <b-form-group
            label="Longitude"
            label-for="lng"
          >
            <b-form-input
              id="lng"
              v-model="point.lng"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BInputGroup,
  BInputGroupAppend, BInputGroupPrepend, BButton,
} from 'bootstrap-vue'
import { gmapApi } from 'vue2-google-maps'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      point: this.data,
      currentPlace: null,
      markers: [],
      places: [],
    }
  },
  computed: {
    center: {
      get() {
        return { lat: this.point.lat, lng: this.point.lng }
      },
      set(newValue) {
        return newValue
      },

    },
    google: gmapApi,

  },
  mounted() {
    // this.geolocate()
    this.$watch('data', (newValue, oldValue) => {
      this.point = newValue
      const position = { lat: this.point.lat, lng: this.point.lng }
      this.addMarker(position)
      this.setCoordinates(position)
      this.setAutocomplete(position)
    })
  },

  methods: {
    // geolocate() {
    //   navigator.geolocation.getCurrentPosition(position => {
    //     this.center = {
    //       lat: position.coords.latitude,
    //       lng: position.coords.longitude,
    //     }
    //   })
    // },
    reset() {
      this.$refs.autocomplete.$refs.input.value = null
    },
    setAutocomplete(position) {
      const geocoder = new this.google.maps.Geocoder()
      geocoder.geocode({ latLng: position }, (result, status) => {
        if (status === 'OK') {
          // accessing autocomplete reference and populating the address
          this.$refs.autocomplete.$refs.input.value = result[0].formatted_address
        }
      })
    },
    setPlace(place) {
      if (place !== undefined && Object.keys(place.geometry).length !== 0) {
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()
        this.currentPlace = place
        // this.setMarker({ lat, lng })
        this.addMarker({ lat, lng })
        this.setCoordinates({ lat, lng })
      }
    },
    setCoordinates(position) {
      this.point.lat = position.lat
      this.point.lng = position.lng
    },
    updatePosition(position) {
      const lat = position.lat()
      const lng = position.lng()
      this.setCoordinates({ lat, lng })
      this.setAutocomplete({ lat, lng })
    },
    addMarker(position) {
      if (position.hasOwnProperty('lat')) {
        this.markers.push({ position })
      }
      if (!position.hasOwnProperty('lat') && this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        }
        this.markers.push(marker)
        this.places.push(this.currentPlace)
        this.currentPlace = null
      }
    },
  },

}
</script>
