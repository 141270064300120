<template>
  <div>

    <div
      v-if="!restricted"
      v-show="!loading"
      :key="elementKey"
    >
      <validation-observer ref="simpleRules">
        <b-form
          ref="point"
          @submit.prevent="submit"
        >
          <b-row>
            <b-col>
              <post-actions :data="pointData.data" />
            </b-col>
          </b-row>
          <b-row class="match-height">
            <b-col
              lg="12"
              md="6"
            >
              <point-overview
                :data="pointData.data"
              />
            </b-col>
            <b-col
              lg="6"
              md="6"
            >
              <point-time-gaps
                :data="pointData.data"
              />
            </b-col>

            <b-col
              lg="6"
              md="6"
            >
              <point-coordinates :data="pointData.data" />
            </b-col>
            <b-col
              lg="6"
              md="6"
            >
              <point-media
                ref="pointMedia"
                :data="pointData.data"
              />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>

  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BForm,
} from 'bootstrap-vue'

import store from '@/store'
import router from '@/router'
import { showToast } from '@/mixins/notification/toasts'

import { onUnmounted, ref } from '@vue/composition-api'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import NotAuthorized from '@/views/pages/miscellaneous/NotAuthorized.vue'
import PointTimeGaps from './PointsTimeGaps.vue'
import PointCoordinates from './PointsCoordinates.vue'
import PointOverview from './PointsOverview.vue'
import PointMedia from './PointsMedia.vue'
import PostActions from './PointsPostActions.vue'
import pointStoreModule from '../pointStoreModule'
import 'animate.css'

export default {
  components: {
    NotAuthorized,
    PointOverview,
    ValidationObserver,

    BRow,
    BCol,
    PointTimeGaps,
    PointCoordinates,
    PointMedia,
    PostActions,
    BForm,
  },
  mixins: [showToast],

  data() {
    return {
      savedData: {},
      elementKey: 0,
      pointData: {},
      savedPointData: {},
      error404: false,
      restricted: false,
      loading: true,
    }
  },
  watch: {
    $route(to, from) {
      if (to.name === 'apps-points-edit'
              && to.params.id
              && Number.isInteger(parseInt(to.params.id, 10))) {
        this.updatePointData(to.params.id)
      }
      if (to.name === 'apps-points-add') {
        this.initPoint()
      }
    },
    pointData(newValue) {
      store.commit('point/setPoint', this.pointData)
    },
    deep: true,
    immediate: true,
  },
  created() {
    this.pointData = JSON.parse(this.initPointData)
  },
  mounted() {
    if (router.currentRoute.name === 'apps-points-edit'
            && router.currentRoute.params.id
            && Number.isInteger(parseInt(router.currentRoute.params.id, 10))) {
      this.updatePointData(router.currentRoute.params.id)
    }
  },
  methods: {
    updatePointData(pointId) {
      store.dispatch('point/fetchPoint', { id: pointId })
        .then(response => {
          if (response.status === 404) {
            this.error404 = true
            this.showAlert404()
          } else {
            const point = store.getters['point/point']
            const ability = defineAbilityForCurrentUser()
            if (ability.can('update', point)) {
              this.pointData.data = point
              this.$refs.pointMedia.updateMainImage(response.data.data)
              this.$refs.pointMedia.updateFileRecords(response.data.data)
              this.savedPointData = JSON.stringify(response.data)
              this.restricted = false
              this.loading = false
            } else {
              this.restricted = true
              router.push({ name: 'misc-not-authorized' })
            }
          }
        })
    },
    showAlert404() {
      this.$swal({
        title: 'Point Not Found',
        text: 'Please chose next action: ',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Back to points list',
        cancelButtonText: 'Add new Point',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__fadeIn',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          router.push({ name: 'apps-points-list' })
        } else {
          router.push({ name: 'apps-points-add' })
        }
      })
    },
    showAlert(next) {
      this.$swal({
        title: 'Are you sure?',
        text: "You didn't save the point!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, I\'m sure',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__fadeIn',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          next()
        }
      })
    },
    submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.save()
        }
      })
    },
    initPoint() {
      const initPointData = JSON.parse(this.initPointData)
      store.commit('point/setPoint', initPointData)
      this.savedPointData = {}
      this.pointData = initPointData
      this.elementKey += 1
    },
    save() {
      if (router.currentRoute.name === 'apps-points-add') {
        store.dispatch('point/addPoint', this.pointData).then(response => {
          if ([200, 201, 'success'].includes(response.status)) {
            this.$refs.pointMedia.uploadFiles(response.data.data.id)
            this.initPoint()
          }
          this.showToast(response, 'Point')
        })
      }

      if (router.currentRoute.name === 'apps-points-edit'
              && router.currentRoute.params.id
              && Number.isInteger(parseInt(router.currentRoute.params.id, 10))) {
        const payload = { data: this.pointData, id: router.currentRoute.params.id }
        store.dispatch('point/updatePoint', payload).then(response => {
          this.$refs.pointMedia.uploadFiles(payload.id)
          this.savedPointData = JSON.stringify(this.pointData)
          this.showToast(response, 'Point')
        })
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.savedPointData !== JSON.stringify(this.pointData) && !this.error404 && !this.restricted) {
      this.showAlert(next)
    } else {
      next()
    }
  },
  setup() {
    // const POINT_APP_STORE_MODULE_NAME = 'point'
    //
    // // Register module
    // if (!store.hasModule(POINT_APP_STORE_MODULE_NAME)) store.registerModule(POINT_APP_STORE_MODULE_NAME, pointStoreModule)
    //
    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(POINT_APP_STORE_MODULE_NAME)) store.unregisterModule(POINT_APP_STORE_MODULE_NAME)
    // })
    const pointData = {
      data: {
        point_type_id: null,
        point_purpose_id: null,
        name: '',
        description: '',
        lng: 0,
        lat: 0,
        estimated_time_of_stay: 5,
        post_status: 'draft',
      },
    }
    const initPointData = JSON.stringify(pointData)
    return {
      pointData,
      initPointData,
    }
  },
}
</script>
