<template>
  <b-card
    no-body
    border-variant="primary"
    bg-variant="transparent"
    class="shadow-none"
  >
    <b-card-body>
      <b-row class="justify-content-end">
        <b-col
          md="6"
          xl="4"
        >
          <b-form-group
            label="Post Status"
            label-for="postStatus"
          >
            <!-- submit button -->
            <b-row>
              <b-col class="col-md-6 col-sm-12">
                <v-select
                  id="postStatus"
                  ref="postStatus"
                  v-model="pointData.post_status"
                  :options="optionPostStatuses"
                  :reduce="title => title.slug"
                  label="title"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                >
                  <template #option="{ title, icon }">
                    <feather-icon
                      v-if="icon"
                      :icon="icon"
                      size="16"
                      class="align-middle mr-50"
                    />
                    <span> {{ title }}</span>
                  </template>
                </v-select>
              </b-col>
              <b-col class="col-md-4">
                <b-button
                  variant="primary"
                  type="submit"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BFormGroup, BRow, BCol, BCard, BCardBody, BButton,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { postStatuses } from '@/mixins/options'

export default {
  components: {
    vSelect,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
  },
  mixins: [postStatuses],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pointData: this.data,
      optionPostStatuses: [],

    }
  },

  mounted() {
    this.$watch('data', (newValue, oldValue) => {
      this.pointData = newValue
    })
  },
  created() {
    this.getOptionPostStatuses()
  },
  methods: {
    getOptionPostStatuses() {
      this.optionPostStatuses = this.postStatuses.filter(status => status.slug !== 'incomplete')
    },
  },
}
</script>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
