<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>
        <div class="d-flex">
          <feather-icon
            icon="CameraIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Media
          </h4>
        </div>
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <b-form-group
                  label="Main Image"
                  label-for="mainImage"
                  class="mb-2"
          >
            <div id="mainImage">
              <VueFileAgent
                ref="vfaMainImage"
                :theme="'list'"
                :deletable="true"
                :meta="true"
                :multiple="false"
                :accept="'image/*'"
                :maxSize="'10MB'"
                :maxFiles="1"
                :thumbnailSize="120"
                :helpText="'Choose main image'"
                :errorText="{
                  type: 'Invalid file type. Only images Allowed',
                  size: 'Files should not exceed 10MB in size',
                }"
                @select="filesSelected($event)"
                @beforedelete="onBeforeDeleteMainImage($event)"
                @delete="fileDeletedMainImage($event)"
                v-model="mainImage"
              ></VueFileAgent>
            </div>

          </b-form-group>
          <b-form-group
                  label="Files Gallery"
                  label-for="filesGallery"
                  class="mb-2"
          >
          <div id="filesGallery">
          <VueFileAgent
            ref="vfaFilesGallery"
            :theme="'list'"
            :multiple="true"
            :deletable="true"
            :meta="true"
            :accept="'image/*,video/*'"
            :maxSize="'10MB'"
            :sortable="'hold'"
            :maxFiles="14"
            :thumbnailSize="120"
            :helpText="'Choose images or videos'"
            :errorText="{
              type: 'Invalid file type. Only images or videos are Allowed',
              size: 'Files should not exceed 10MB in size',
            }"
            @select="filesSelected($event)"
            @beforedelete="onBeforeDelete($event)"
            @delete="fileDeleted($event)"
            v-model="fileRecords"
          ></VueFileAgent>
        </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
  import { BCard, BCardBody, BCardHeader, BCardTitle, BCol, BForm, BFormGroup, BFormInput, BRow, } from 'bootstrap-vue'
  import store from '@/store'
  import VueFileAgentPlugin from 'vue-file-agent'
  import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css'
  import { ValidationProvider } from 'vee-validate'
  import { showToast } from '@/mixins/notification/toasts'
  const VueFileAgent = VueFileAgentPlugin.VueFileAgent
export default {
  components: {
    BFormInput, BFormGroup, BForm, BRow, BCol, BCard, BCardBody, BCardHeader, BCardTitle, VueFileAgent, VueFileAgentStyles,ValidationProvider,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  mixins: [showToast],
  data() {
    return {
      fileRecords: [],
      fileRecordsForUpload: [],
      uploadedFileRecords: [],
      mainImage: [],
      uploadedMainImage: [],
      mainImageForUpload: [],
    }
  },
  methods: {
    //TODO move to helpers
    getDifference(array1, array2) {
      return array1.filter(object1 => {
        return !array2.some(object2 => {
          return object1.id === object2.id;
        });
      });
    },
    updateMainImage(point){
      let files = []
      if(point.main_image!==null && Object.keys(point.main_image).length !==0){
        files.push(point.main_image)
      }
      // TODO remove after all needed properties will be gotten from the server
      if (typeof files !== 'undefined' && files.length > 0){
        files.forEach((file)=>{
          file.type = file.type?file.type:"image"
          file.size = file.size?file.size:561813
          file.sizeText = file.sizeText?file.sizeText:"549 KB"
          file.ext = file.ext?file.ext:file.name.split('.').pop();
          // file.dimensions = {
          //   "width": 640,
          //   "height": 360
          // }
          file.imageColor= [38, 131, 143]
        })
      }

      this.uploadedMainImage = files
      this.mainImage = files
    },
    updateFileRecords(point){
      let files = []
      if(point.media_files!==null && Object.keys(point.media_files).length !==0){
        files = point.media_files
      }
      // TODO remove after all needed properties will be gotten from the server
      if (typeof files !== 'undefined' && files.length > 0){
        files.forEach((file)=>{
          file.type = file.type?file.type:"image"
          file.size = file.size?file.size:561813
          file.sizeText = file.sizeText?file.sizeText:"549 KB"
          file.ext = file.ext?file.ext:file.name.split('.').pop();
          file.imageColor= [253, 253, 253]
          file.videoThumbnail= file.url
        })
      }

      this.uploadedFileRecords = files
      this.fileRecords = files
    },
    uploadFiles (pointId){
      let mainImage = 'false'
      const newFileRecords = [
        ...this.getDifference(this.fileRecords, this.uploadedFileRecords),
        ...this.getDifference(this.uploadedFileRecords, this.fileRecords)
      ]
      const newMainImage = [
        ...this.getDifference(this.mainImage, this.uploadedMainImage),
      ]
      if(newMainImage.length > 0){
        newFileRecords.unshift(newMainImage[0])
        mainImage = 'true'
      }
      this.fileRecordsForUpload = [...newFileRecords]

      if(typeof newFileRecords !== 'undefined' && newFileRecords.length > 0){
        newFileRecords.forEach ( ( fileData ,index) => {
          const formData = new FormData()
          formData.append(fileData.file.name, fileData.file, fileData.file.name)
          formData.set('image', fileData.file)
          formData.set('point_id', pointId)
          formData.set('default', mainImage)
          store.dispatch('point/addImages', formData).then( response => {
            this.showToast(response, 'Point Media File')
            this.fileRecordsForUpload.splice ( index , 1 )
          })
          mainImage = 'false'
        })
      }
    },

    deleteUploadedFile(file) {
      const data = {}
      const point = store.getters['point/point']
      data.pointId = point.id
      data.imageIds= file.id
      store.dispatch('point/deleteImages', data).then( response => {
        this.showToast(response, 'Point Media File')
      })
    },

    filesSelected(fileRecordsNewlySelected) {
      const validFileRecords = fileRecordsNewlySelected.filter(fileRecord => !fileRecord.error)
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords)
    },

    onBeforeDeleteMainImage(fileRecord) {
      const i = this.mainImageForUpload.indexOf(fileRecord)
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.mainImageForUpload.splice(i, 1)
        const k = this.mainImage.indexOf(fileRecord)
        if (k !== -1) this.mainImage.splice(k, 1)
      } else {
        // will trigger 'delete' event
        this.showAlert('vfaMainImage',fileRecord)
      }
    },

    onBeforeDelete(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1)
        const k = this.fileRecords.indexOf(fileRecord)
        if (k !== -1) this.fileRecords.splice(k, 1)
      } else  {
        // will trigger 'delete' event
        this.showAlert('vfaFilesGallery', fileRecord)
      }
    },

    fileDeletedMainImage(fileRecord) {
      const i = this.mainImageForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.mainImageForUpload.splice(i, 1)
      } else {
        this.deleteUploadedFile(fileRecord)
      }
    },

    fileDeleted(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
      } else {
        this.deleteUploadedFile(fileRecord)
      }
    },

    showAlert(ref,fileRecord) {
      this.$swal({
        title: 'Are you sure?',
        text: "Do you want to delete the file?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, I\'m sure',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__fadeIn',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$refs[ref].deleteFileRecord(fileRecord)
        }
      })
    },
  },

}
</script>
