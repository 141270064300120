export const pointTypes = {
  data() {
    return {
      pointTypes: [
        {
          title: 'Target',
          slug: 'target',
          icon: 'CrosshairIcon',
          tooltip: 'somedesc',

        },
        {
          title: 'Intermediate',
          slug: 'intermediate',
          icon: 'MapPinIcon',
          tooltip: 'somedesc',
        },
        {
          title: 'Turn',
          slug: 'turn',
          icon: 'CompassIcon',
          tooltip: 'somedesc',
        },
      ],
    }
  },
}
export const postStatuses = {
  data() {
    return {
      postStatuses: [
        {
          title: 'Draft',
          slug: 'draft',
          icon: 'EyeOffIcon',
          class: 'warning',
        },
        {
          title: 'Published',
          slug: 'published',
          icon: 'EyeIcon',
          class: 'success',
        },
        {
          title: 'Archived',
          slug: 'archived',
          icon: 'ArchiveIcon',
          class: 'dark',
        },
        {
          title: 'Incomplete',
          slug: 'incomplete',
          icon: 'AlertTriangleIcon',
        },
      ],
    }
  },
}
export const _ = null
