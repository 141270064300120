<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>
        <div class="d-flex">
          <feather-icon
            icon="BookOpenIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Overview
          </h4>
        </div>
      </b-card-title>
    </b-card-header>
    <b-card-body>

      <!-- form -->
      <b-row>
        <b-col
          md="12"
          lg="6"
          xl="4"
        >
          <b-form-group
            label="Point Type"
            label-for="pointType"
          >
            <validation-provider
              #default="{ errors }"
              name="Point Type"
              rules="required"
            >
              <v-select
                id="pointType"
                v-model="pointType"
                :options="optionPointTypes"
                :reduce="title => title.id"
                label="title"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              >
                <template #option="{ title, icon }">
                  <feather-icon
                    v-if="icon"
                    :icon="icon"
                    size="16"
                    class="align-middle mr-50"
                  />
                  <span> {{ title }}</span>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Point Purposes"
            label-for="pointPurposes"
          >
            <validation-provider
              #default="{ errors }"
              name="Point Purposes"
              rules="required"
            >
              <v-select
                id="pointPurposes"
                ref="pointPurposes"
                v-model="pointPurposes"
                :options="optionPointPurposes"
                :reduce="title => title.id"
                label="title"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              >
                <template #option="{ title, icon }">
                  <feather-icon
                    v-if="icon"
                    :icon="icon"
                    size="16"
                    class="align-middle mr-50"
                  />
                  <span> {{ title }}</span>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="12"
          lg="6"
          xl="4"
        >
          <b-form-group
            label="Point Name"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input
                v-model="pointData.name"
                :state="errors.length > 0 ? false:null"
                placeholder="City Hall"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          md="12"
          lg="12"
          xl="4"
        >
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="pointData.description"
              rows="4"
              placeholder="City main culture center..."
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BCard, BCardBody, BCardHeader, BCardTitle, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import {
  required, confirmed,
} from '@validations'
import vSelect from 'vue-select'
import store from '@/store'
import { pointTypes } from '@/mixins/options'
import { ref } from '@vue/composition-api'

export default {
  components: {
    ValidationProvider,
    vSelect,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BFormTextarea,
  },
  mixins: [pointTypes],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pointType: null,
      optionPointTypes: [],
      optionPointPurposes: [],
      required,
      confirmed,
      pointData: this.data,
      pointTypesAndPurposes: {},
    }
  },
  computed: {
    pointPurposes: {
      get() {
        return this.data.point_purpose_id
      },
      set(newValue) {
        this.data.point_purpose_id = newValue
      },
    },
  },
  mounted() {
    this.$watch('data', (newValue, oldValue) => {
      this.pointData = newValue
      this.pointPurposes = newValue.point_purpose_id
      this.pointType = newValue.point_type_id
    })

    this.$watch('pointType', (newValue, oldValue) => {
      this.data.point_type_id = newValue
      const type = this.pointTypesAndPurposes.find(({ id }) => id === newValue)
      if (Object.keys(type.point_purposes).length !== 0) {
        this.optionPointPurposes = type.point_purposes
        if (oldValue !== null && oldValue !== newValue) {
          this.$refs.pointPurposes.clearSelection()
        }
      }
    }, { flush: 'post' })
  },
  created() {
    const pointTypesAndPurposes = ref(null)
    store.dispatch('point/getPointTypesAndPurposes')
      .then(response => {
        if (response.data) {
          this.pointTypesAndPurposes = response.data
          this.getOptionPointTypes(response.data)
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          pointTypesAndPurposes.value = undefined
        }
      })

    this.pointType = this.data.point_type_id
  },
  methods: {
    getOptionPointTypes(options) {
      // Add icons, tooltips etc
      if (options) {
        const result = []
        options.forEach(obj => {
          const type = this.pointTypes.find(({ slug }) => slug === obj.slug && obj.slug !== 'turn')
          if (type) {
            result.push({ ...type, ...obj })
          }
        })
        this.optionPointTypes = result
      }
    },
  },
  setup() {

  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
